import { Script } from 'gatsby';
import React, { useCallback, useRef, useState } from 'react';
import {
  autocompleteOptions,
  convertAutocompleteDataToHubspotFields,
} from '../../components/Forms/utils/autocomplete';
import PartyPopperIcon from '../../images/party-icon.svg';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Image from '../../utils/OptimizedImage';
import FormInput from '../shared/FormControl/Input';

const Completed = ({ onViewReports }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [autocompleteFields, setAutocompleteFields] = useState({});
  const placesAutocomplete = useRef(null);

  const initPlacesAutocomplete = () => {
    if (window.google?.maps?.places?.Autocomplete) {
      const triggerInput = document.querySelector(
        '.form-content [name="company"]'
      );

      placesAutocomplete.current = new window.google.maps.places.Autocomplete(
        triggerInput,
        autocompleteOptions
      );

      // Set name value and hidden field values when a place is selected
      placesAutocomplete.current.addListener('place_changed', () => {
        const selectedPlace = placesAutocomplete.current.getPlace();
        const convertedFields = convertAutocompleteDataToHubspotFields(
          selectedPlace
        );

        if (convertedFields.company) {
          setCompany(convertedFields.company);
        }

        setAutocompleteFields(convertedFields);
      });

      // Clear hidden field values when the company name input value changes
      triggerInput.addEventListener('change', () => {
        setAutocompleteFields({});
      });
    }
  };

  const handleFirstName = useCallback(
    ({ target }) => setFirstName(target.value),
    []
  );

  const handleLastName = useCallback(
    ({ target }) => setLastName(target.value),
    []
  );

  const handleEmail = useCallback(({ target }) => setEmail(target.value), []);

  const handlePhone = useCallback(({ target }) => setPhone(target.value), []);

  const handleCompany = useCallback(
    ({ target }) => setCompany(target.value),
    []
  );

  const handleViewReports = useCallback(() => {
    setSubmitting(true);
    if (!(firstName && lastName && email && phone)) {
      setError('Please complete all the required fields');
    } else {
      setError('');
      onViewReports(
        firstName,
        lastName,
        email,
        phone,
        company,
        autocompleteFields
      );
    }
  }, [
    company,
    email,
    autocompleteFields,
    firstName,
    lastName,
    onViewReports,
    phone,
  ]);

  return (
    <>
      <div className="completed-calc">
        <Image src={PartyPopperIcon} alt="success" />
        <div className="title-wrapper">
          <p className="form-title">No-Show Loss Calculator Completed!</p>
          <p className="sub-title">
            Submit your name and info to view your results
          </p>
        </div>

        <div className="form-content">
          <div className="input-row">
            <div className="input-wrapper">
              <label htmlFor="firstName">First name</label>
              <FormInput
                required
                id="firstName"
                name="firstName"
                placeholder="First name"
                error={
                  !firstName && submitting && 'First name field is required!'
                }
                value={firstName}
                onChange={handleFirstName}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="lastName">Last name</label>
              <FormInput
                required
                id="lastName"
                name="lastName"
                placeholder="Last name"
                error={
                  !lastName && submitting && 'Last name field is required!'
                }
                value={lastName}
                onChange={handleLastName}
              />
            </div>
          </div>

          <div className="input-row">
            <div className="input-wrapper">
              <label htmlFor="emailAddress">Email address</label>
              <FormInput
                required
                id="emailAddress"
                name="emailAddress"
                placeholder="Email address"
                type="email"
                error={!email && submitting && 'Email field is required!'}
                value={email}
                onChange={handleEmail}
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="phoneNumber">Phone number</label>
              <FormInput
                required
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone number"
                error={
                  !phone && submitting && 'Phone number field is required!'
                }
                value={phone}
                onChange={handlePhone}
              />
            </div>
          </div>

          <div className="input-row">
            <div className="input-wrapper">
              <label htmlFor="company">Shop Name</label>
              <FormInput
                required
                id="company"
                name="company"
                placeholder="Enter name of shop"
                type="text"
                error={!company && submitting && 'Shop name field is required!'}
                value={company}
                onChange={handleCompany}
              />
            </div>
          </div>

          {error && <p className="text-danger fs-16">{error}</p>}

          <CallToAction
            variant="secondary"
            size="lg"
            className="w-100 mb-3"
            value="View Reports"
            customHandleClick={handleViewReports}
          />
        </div>
      </div>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`}
        onLoad={initPlacesAutocomplete}
      />
    </>
  );
};

export default Completed;
