import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;

  input {
    ${props => (props.info ? 'padding-right: 46px !important;' : '')}
  }

  .info-icon {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: 2px solid rgba(0, 0, 0, 0.54);
    position: absolute;
    right: 22.5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: help;
    font-size: 12px;
    font-weight: 700;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: rgba(0, 0, 0, 0.54);

    &::after {
      content: 'i';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
