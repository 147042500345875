import clsx from 'clsx';
import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ErrorHelperText from './ErrorHelperText';
import { InputContainer } from './styles';

const FormInput = React.forwardRef(
  ({ className, error, id, info, label, name, ...props }, ref) => {
    const tooltip = (
      <Tooltip placement="top" style={{ 'border-color': 'black' }}>
        {info}
      </Tooltip>
    );

    return (
      <Form.Group className={clsx('form-group', className)} ref={ref}>
        {label && (
          <Form.Label htmlFor={id} className="control-label">
            {label}
          </Form.Label>
        )}
        <InputContainer info={info}>
          <Form.Control
            {...props}
            id={id}
            name={name}
            className={clsx('form-control', error && 'is-invalid')}
          />
          {info && (
            <OverlayTrigger placement="top" overlay={tooltip}>
              <span className="info-icon" />
            </OverlayTrigger>
          )}
        </InputContainer>

        <ErrorHelperText errMessage={error} />
      </Form.Group>
    );
  }
);

export default FormInput;
