import FormHelperText from '@material-ui/core/FormHelperText';
import React from 'react';
import { Fade } from 'react-bootstrap';

const ErrorHelperText = ({ errMessage }) => (
  <Fade in={!!errMessage}>
    <FormHelperText error>{errMessage}</FormHelperText>
  </Fade>
);

export default ErrorHelperText;
